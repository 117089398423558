<template>
    <div style="padding:10px;" >
        <!-- 功能区域 -->
        <div style="margin: 10px 0">
            <el-button type="primary" @click="add">新增</el-button>
        </div>

        <!-- 搜索区域 -->
        <div style="margin: 10px 0">
            <el-input v-model="search" placeholder="请输入" style="width: 20%" clearable/>
            <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
        </div>

        <!-- 表格 -->
        <el-table :data="tableData" stripe border style="width: 100%" >
            <el-table-column prop="id" label="ID" sortable />
            <el-table-column prop="bookName" label="物流单号" sortable />
            <el-table-column prop="title" label="标题" />
            <el-table-column prop="content" label="内容" />
            <el-table-column prop="formatTime" label="物流时间" />
          <el-table-column prop="time" label="操作时间" />
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-popconfirm title="确认删除吗?" @confirm="handleDelete(scope.row.id)">
                        <template #reference>
                            <el-button size="mini" type="danger">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div style="margin: 10px 0">
            <el-pagination
                    v-model:currentPage="currentPage"
                    :page-sizes="[5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>

            <!-- 新增 弹窗提示 -->
            <el-dialog title="提示" v-model="dialogVisible" width="50%">
                <el-form :model="form" label-width="120px" :rules="rules">
                    <el-form-item label="物流单号" prop="bookId">
                      <el-select v-model="form.bookId" filterable placeholder="请选择物流单号" style="width:50%">
                        <el-option v-for="task in bookList"
                                   :key="task.id"
                                   :label="task.name"
                                   :value="task.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="标题" prop="title">
                        <el-input v-model="form.title" style="width:50%"></el-input>
                    </el-form-item>
                    <div id="div1"></div>
                    <el-form-item label="内容" prop="content">
                        <el-input v-model="form.content" style="width:80%"></el-input>
                    </el-form-item>
                    <el-form-item label="物流时间" prop="time">
                      <el-date-picker v-model="form.time" value-format="YYYY-MM-DD HH:mm:ss"
                                      format="YYYY-MM-DD HH:mm:ss" type="datetime"  style="width:50%" clearable></el-date-picker>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import request from "../utils/request";

    export default {
        name: 'News',
        components: {},
        data() {
            return {
                bookList: [],
                form: {},
                dialogVisible: false,
                search: '',
                currentPage:1,
                pageSize:10,
                total:0,
                tableData: [],
                vis:false,
                detail:{},
                rules: {
                  bookId: [{required: true, message: '请选择物流单号', trigger: 'blur',}],
                  title: [{required: true, message: '请输入标题', trigger: 'blur',}],
                  content: [{required: true, message: '请输入内容', trigger: 'blur',}],
                  time: [{required: true, message: '请选择时间', trigger: 'blur',}],
                }
            }
        },
        created() {
            this.load()
        },
        methods: {
            load(){
              request.get("/news/list",{
                    params:{
                        pageNum:this.currentPage,
                        pageSize:this.pageSize,
                        search:this.search
                    }
                }).then(res => {
                    console.log(res)
                    this.tableData = res.data.records
                    this.total = res.data.total
                })
              request.post("/book/all").then(res => {
                console.log(res)
                this.bookList = res.data;
              })
            },
            filesUploadSuccess(res){
                this.form.cover = res.data
            },
            add(){
                this.dialogVisible = true
                this.form={}
            },
            save(){

              if (!this.form.bookId) {
                this.$message({
                  type: "error",
                  message: '请选择物流单号！'
                })
                return;
              }
              if (!this.form.title) {
                this.$message({
                  type: "error",
                  message: '请输入标题！'
                })
                return;
              }
              if (!this.form.content) {
                this.$message({
                  type: "error",
                  message: '请输入内容！'
                })
                return;
              }
              if (!this.form.time) {
                this.$message({
                  type: "error",
                  message: '请选择时间！'
                })
                return;
              }
                if(this.form.id){ //判断是否拥有用户ID 有则更新
                  request.post("/news/update",this.form).then(res => {
                        console.log(res)
                        if(res.code == '0'){
                            this.$message({
                                type: "success",
                                message: "更新成功"
                            })
                        }else{
                            this.$message({
                                type: "error",
                                message: res.msg
                            })
                        }
                        this.load() //刷新表格数据
                        this.dialogVisible = false //关闭弹窗
                    })
                }else{ //新增
                    let userStr = sessionStorage.getItem("user") || "{}"
                    let user = JSON.parse(userStr)
                    this.form.author = user.nickName
                     request.post("/news/save",this.form).then(res => {
                        console.log(res)
                        if(res.code === '0'){
                            this.$message({
                                type: "success",
                                message: "新增成功"
                            })
                        }else{
                            this.$message({
                                type: "error",
                                message: res.msg
                            })
                        }
                        this.load() //刷新表格数据
                        this.dialogVisible = false //关闭弹窗
                    })
                }
            },
            handleEdit(row){
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisible = true
            },
            handleDelete(id){
                console.log(id)
                request.delete("/news/"+id).then(res => {
                    if(res.code === '0'){
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        })
                    }else{
                        this.$message({
                            type: "error",
                            message: res.msg
                        })
                    }
                    this.load() //重新加载数据
                })
            },
            handleSizeChange(pageSize){ //改变当前每页的个数触发
                this.pageSize = pageSize
                this.load()
            },
            handleCurrentChange(pageNum){ //改变当前页码触发
                this.currentPage = pageNum
                this.load()
            }
        }
    }
</script>
